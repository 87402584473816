import type { LoginResponse } from '@dx-ui/osc-login';
import type { HeaderProps, TDrawerItem } from '@dx-ui/osc-header';
import { Header } from '@dx-ui/osc-header';
import { useAuth, getGuestFirstName } from '@dx-ui/framework-auth-provider';
import { baseUrl, goUserTypes } from '../../helpers/constants';
import { useStaticUrls } from '../../hooks/useStaticUrls';
import { useMetrics } from '../../hooks/use-metrics';
import { sendRewardAsync } from '@dx-ui/framework-conductrics';
import { generateUrl } from '../../helpers/property-utils';
import { useRouter } from 'next/router';
import { GOALS } from '../../helpers/conductricsConstants';
import { mapCustomClassesToComponent } from '../../helpers/themes/customTheme';
import { useTranslation } from 'next-i18next';
import useUserFromGuestInfo from '../../hooks/use-user-from-guest-info';

export type THeaderWrapper = {
  brandCode: string;
  brandName: string;
  lang: string;
  mainNavLinks?: TDrawerItem[];
  suppressLogo?: boolean;
  ctyhocn: string;
};

export const HeaderWrapper = function ({
  brandCode,
  brandName,
  lang,
  mainNavLinks,
  suppressLogo,
  ctyhocn,
}: THeaderWrapper) {
  const { login, logout, guestInfo, isAuthenticated } = useAuth();
  const metrics = useMetrics();
  const { honorsAccountUrl, honorsJoinUrl, honorsSignInUrl } = useStaticUrls();
  const { t } = useTranslation('header');
  const router = useRouter();
  const baseUrlWithLocale = `${baseUrl}/${router.locale}`;
  const user = useUserFromGuestInfo(guestInfo);

  const onSignInAttempt = async (data: LoginResponse): Promise<void> => {
    if (data) {
      let availableGoUserTypes: string[] = [];
      // TODO: NHCBP-3343 - verify packages is right
      if (guestInfo?.hhonors?.packages?.filter) {
        availableGoUserTypes = guestInfo?.hhonors?.packages
          ?.filter((pack) => pack?.packageName && goUserTypes.includes(pack.packageName))
          ?.map((pack) => pack && pack.packageName);
      }
      metrics.trackUserLoggedIn([
        {
          hhonorsNumber: guestInfo?.hhonors?.hhonorsNumber || '',
          tierName: guestInfo?.hhonors?.summary?.tierName || '',
          // TODO: NHCBP-3343 - verify whether this should be 'totalPoints' or 'totalPointsFmt' (it was 'totalPoints', but that is a Number)
          points: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
          goUserTypes: availableGoUserTypes,
        },
      ]);
    }
    await login(data);
    if (data?.data) {
      await sendRewardAsync(GOALS.SUCCESSFUL_LOGIN_FROM_PROPERTY);
    }
  };

  const onSignOut = logout;
  const { relativeUrl } = generateUrl(router);

  const customClassName = mapCustomClassesToComponent('globalNav', brandCode);

  const headerProps: HeaderProps = {
    brand: {
      code: brandCode,
      name: brandName,
      url: relativeUrl,
    },
    className: `border-border border-b border-solid ${customClassName}`,
    isFluid: true,
    findStayLink: { url: `${baseUrlWithLocale}/book/reservation/find/` },
    megaNavLinks: mainNavLinks,
    onSignInAttempt,
    onSignOut,
    showNewHeader: true,
    suppressLogo,
    user:
      isAuthenticated && guestInfo
        ? {
            name: getGuestFirstName({ guestInfo }),
            honorsTier: guestInfo?.hhonors?.summary?.tierName || '',
            honorsPoints: guestInfo?.hhonors?.summary?.totalPoints || 0,
            honorsPointsFmt: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
          }
        : undefined,
    userLinks: {
      signInLink: { url: honorsSignInUrl },
      signUpLink: { url: honorsJoinUrl, isNewWindow: true },
      accountLink: { url: honorsAccountUrl },
      additionalLinks: [
        {
          label: t('activity'),
          url: `${baseUrlWithLocale}/hilton-honors/guest/activity/`,
        },
        {
          label: t('pointsLabel'),
          url: `${baseUrlWithLocale}/hilton-honors/guest/points/`,
        },
        {
          label: t('profile'),
          url: `${baseUrlWithLocale}/hilton-honors/guest/profile/`,
        },
        ...(user?.isTeamMember
          ? [
              {
                label: t('goHiltonProgramDetails'),
                url: `https://help.hilton.com/s/article/What-is-the-Go-Hilton-employee-program`,
              },
              {
                label: t('manageFriendsAndFamily'),
                url: `https://tmtp.hilton.com/tmtp/main.html`,
              },
            ]
          : []),
        ...(user?.isHGVMax
          ? [
              {
                label: t('hgvMaxRateDetails'),
                url: `https://help.hilton.com/s/article/HGV-Max-rate`,
              },
            ]
          : []),
        ...(user?.isSmbMember
          ? [
              {
                label: t('manageHiltonForBusiness'),
                url: `${baseUrlWithLocale}/business/manage/`,
              },
            ]
          : []),
      ],
    },
    languageSelectorOptions: {
      locale: lang,
      ctyhocn,
      appName: 'dx-property-ui',
      popupOptions: {
        includeTitles: true,
      },
      buttonOptions: {
        className: 'text-sm',
      },
    },
  };

  return <Header {...headerProps} />;
};
