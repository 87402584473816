import config from 'next/config';
import { useRouter } from 'next/router';
import { baseUrl } from '../helpers/constants';

const { publicRuntimeConfig } = config();
const safeAutUI = publicRuntimeConfig?.DX_AUTH_UI ?? '';
const safeJoinUrl: string = publicRuntimeConfig?.HONORS_JOIN_URL ?? '';
const forgetInfoUri = publicRuntimeConfig?.PARTNER_FORGET_INFO_LINK ?? '';

const replaceLANG = (stringValue: string, lang = 'en') => stringValue?.replace('__LANG__', lang);

export function useStaticUrls() {
  const router = useRouter();
  const lang = router?.locale || 'en';
  const path = router?.asPath || '/';
  const baseUrlWithLang = `${baseUrl}/${lang}`;

  const baseAppUrl = `${baseUrlWithLang}${path}`;
  const honorsSignInUrl = replaceLANG(safeAutUI, lang);
  const honorsJoinUrl = replaceLANG(safeJoinUrl, lang);
  const honorsAccountUrl = `${baseUrlWithLang}/hilton-honors/guest/my-account/`;
  const forgetInfoUrl = replaceLANG(forgetInfoUri, lang);

  return {
    baseAppUrl,
    honorsAccountUrl,
    honorsJoinUrl,
    honorsSignInUrl,
    forgetInfoUrl,
  };
}
