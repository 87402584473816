import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';

export const GetLanguageSelectorOneLinkConfigDocument = {
  operationName: 'featureConfigs',
  operationString: `query featureConfigs {
  featureConfigs(names: ["config-ui-translate-onelink"]) {
    config
    name
  }
}`,
  originalOpName: 'getLanguageSelectorOneLinkConfig',
};
export function useGetLanguageSelectorOneLinkConfigQuery<
  TData = Types.GetLanguageSelectorOneLinkConfigQuery,
  TError = unknown
>(
  variables?: Types.GetLanguageSelectorOneLinkConfigQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.GetLanguageSelectorOneLinkConfigQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.GetLanguageSelectorOneLinkConfigQuery, TError, TData>({
    queryKey: [GetLanguageSelectorOneLinkConfigDocument, variables],
    ...options,
  });
}
export function serverSideGetLanguageSelectorOneLinkConfigQuery(
  queryClient: QueryClient,
  variables?: Types.GetLanguageSelectorOneLinkConfigQueryVariables,
  options?: FetchQueryOptions<Types.GetLanguageSelectorOneLinkConfigQuery>
) {
  return queryClient.fetchQuery<Types.GetLanguageSelectorOneLinkConfigQuery>({
    queryKey: [GetLanguageSelectorOneLinkConfigDocument, variables],
    ...options,
  });
}

export const GetLanguageSelectorHotelDataDocument = {
  operationName: 'hotel',
  operationString: `query hotel($ctyhocn: String!) {
  hotel(ctyhocn: $ctyhocn, language: "en") {
    localization {
      languageSupport {
        label
        bookingUrl
        code
        siteUrl
      }
    }
  }
}`,
  originalOpName: 'getLanguageSelectorHotelData',
};
export function useGetLanguageSelectorHotelDataQuery<
  TData = Types.GetLanguageSelectorHotelDataQuery,
  TError = unknown
>(
  variables: Types.GetLanguageSelectorHotelDataQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.GetLanguageSelectorHotelDataQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.GetLanguageSelectorHotelDataQuery, TError, TData>({
    queryKey: [GetLanguageSelectorHotelDataDocument, variables],
    ...options,
  });
}
export function serverSideGetLanguageSelectorHotelDataQuery(
  queryClient: QueryClient,
  variables: Types.GetLanguageSelectorHotelDataQueryVariables,
  options?: FetchQueryOptions<Types.GetLanguageSelectorHotelDataQuery>
) {
  return queryClient.fetchQuery<Types.GetLanguageSelectorHotelDataQuery>({
    queryKey: [GetLanguageSelectorHotelDataDocument, variables],
    ...options,
  });
}
