import { Footer } from '@dx-ui/osc-footer';
import { HotelAddress } from '@dx-ui/osc-hotel-address';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { HotelPage } from '../../helpers/constants';
import type { LayoutData } from '../../helpers/layout.types';
import { getHotelsRouteParams } from '../../helpers/routing';
import { useInitializeConductrics } from '../../hooks/use-initialize-conductrics';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { HeaderWrapper } from '../header';
import SearchWidget from '../search/SearchWidget';
import PreSellOpenAlert from './PreSellOpenAlert';
import HotelChip from './HotelChip';
import { useRenovationFlagTest } from '../../hooks/useRenovationFlagTest';
import { useNewHotelChipTest } from '../../hooks/useNewHotelChipTest';
import { mapCustomClassesToComponent } from '../../helpers/themes/customTheme';

type THotelLayoutProps = {
  children: React.ReactNode;
  layout: LayoutData;
  page: ValuesOf<typeof HotelPage>;
  ctyhocn: string;
  showNumAttendees?: boolean;
  traits?: string[];
};

const HotelLayout = ({
  children,
  ctyhocn,
  layout,
  page,
  showNumAttendees,
  traits = [],
}: THotelLayoutProps) => {
  const lang = useRouter().locale || 'en';
  const router = useRouter();
  const { wifi: hasJoinedPropertyWiFi } = getHotelsRouteParams(router);

  const {
    adultAge,
    adultsOnly,
    address,
    ageBasedPricing,
    brandCode: unsafeBrandCode,
    breadCrumbs,
    footerData,
    headerData,
    hotelName,
    isNewHotel,
    isPartnerBrand,
    maxOccupants,
    open,
    openDate,
    preOpenMsg,
    resEnabled,
    resEnabledDate,
    templateType,
  } = layout;

  const brandCode = unsafeBrandCode ?? '';
  const isFromWifi = hasJoinedPropertyWiFi && page === HotelPage.HOTELINFO;
  const shouldDisplayShopFormOnPage = page !== HotelPage.OFFERS && !isFromWifi;
  useInitializeConductrics({
    traits: [
      `template:${templateType}`,
      `Brand:${brandCode}`,
      `newHotel:${isNewHotel ? 'yes' : 'no'}`,
      ...traits,
    ],
  });

  const { showRenovationChipInHeader } = useRenovationFlagTest(ctyhocn);
  const { showNewHotelChip } = useNewHotelChipTest();

  const wrapperClass = mapCustomClassesToComponent('navShopForm', brandCode);

  return (
    <>
      <HeaderWrapper {...headerData} brandCode={brandCode} ctyhocn={ctyhocn} lang={lang} />
      <div className="inset-0 z-0 m-0 w-full flex-col flex-nowrap p-0">
        {!open ? (
          <PreSellOpenAlert
            brandCode={brandCode}
            isResEnabled={resEnabled ?? false}
            lang={lang}
            openDate={openDate ?? ''}
            preOpenMsg={preOpenMsg ?? ''}
            resEnabledDate={resEnabledDate ?? ''}
          />
        ) : null}
        <div className={cx('bg-bg border-b border-border-alt border-solid', wrapperClass)}>
          <div className="container flex flex-col items-center justify-between text-pretty py-6 xl:flex-row">
            {hotelName ? (
              <div className="flex flex-col items-center justify-center text-center xl:me-4 xl:items-start xl:justify-start xl:text-start">
                <h1 className="heading-2xl text-balance">
                  {hotelName}{' '}
                  {showRenovationChipInHeader || showNewHotelChip ? (
                    <HotelChip className="inline-flex align-middle" ctyhocn={ctyhocn} />
                  ) : null}
                </h1>
                {address ? (
                  <div className="pt-3.5 text-center xl:text-balance xl:pt-1 xl:text-start">
                    <HotelAddress
                      hasLink={true}
                      addressLine1={address?.addressLine1 || ''}
                      city={address?.mapCity || ''}
                      countryCode={address?.country || ''}
                      hotelName={hotelName || ''}
                      stateCode={address?.state}
                      singleLineAddress={address.addressFmt || ''}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {resEnabled && shouldDisplayShopFormOnPage ? (
              <div className="pt-6 xl:pt-0" data-testid="search-widget-container">
                <SearchWidget
                  adultAge={adultAge}
                  ageBasedPricing={ageBasedPricing}
                  brandCode={brandCode}
                  ctyhocn={ctyhocn}
                  isAdultsOnly={adultsOnly}
                  isGroupSearch={page === HotelPage.MEETINGSEVENTS}
                  isOpen={open ?? false}
                  isPartnerBrand={isPartnerBrand}
                  isResEnabled={resEnabled}
                  openDate={openDate ?? ''}
                  resEnabledDate={resEnabledDate ?? ''}
                  showNumAttendees={showNumAttendees ?? false}
                  maxOccupants={maxOccupants}
                  hideFlexDates={page === HotelPage.MEETINGSEVENTS}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {children}
      {breadCrumbs ? <BreadCrumbs breadCrumbs={breadCrumbs} /> : null}
      <Footer {...footerData} />
    </>
  );
};

export default HotelLayout;
