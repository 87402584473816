import { getGuestFirstName, type GuestQuery, type useAuth } from '@dx-ui/framework-auth-provider';

type User = {
  name: string;
  honorsTier: string;
  honorsPoints: NonNullable<
    NonNullable<NonNullable<GuestQuery['guest']>['hhonors']>['summary']
  >['totalPoints'];
  honorsNumber: string;
  honorsPointsFmt: string;
  isHGVMax: boolean;
  isSmbMember: boolean;
  isTeamMember: boolean;
};

const useUserFromGuestInfo = (
  guestInfo: ReturnType<typeof useAuth>['guestInfo']
): Required<User | undefined> => {
  if (!guestInfo) return undefined;

  const hHonorsSummary = guestInfo?.hhonors?.summary;
  const hHonorsNumber = guestInfo?.hhonors?.hhonorsNumber;

  return {
    name: getGuestFirstName({ guestInfo }),
    honorsTier: hHonorsSummary?.tierName || '',
    honorsPoints: hHonorsSummary?.totalPoints,
    honorsNumber: hHonorsNumber || '',
    honorsPointsFmt: hHonorsSummary?.totalPointsFmt || '',
    isHGVMax: Boolean(guestInfo?.hhonors?.isOwnerHGVNew),
    isSmbMember: Boolean(guestInfo.hhonors?.isSMBMember),
    isTeamMember: Boolean(guestInfo?.hhonors?.isTeamMember),
  };
};

export default useUserFromGuestInfo;
